import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby";
import Image from "../components/image";
import Gallery from '../components/gallery'
import SEO from "../components/seo";

export default (props) => {
  const categoryName = props.pathContext.categoryName;
  const capitalizeFirst = ([first, ...rest]) => 
  first.toUpperCase() + rest.toLowerCase()
  let html;
  let images;
  try {
    html = props.data.dropboxNode.localFile.childMarkdownRemark.html;
  } catch (error) {
  }
  try {
    images = props.data.allDropboxNode.edges.map(edge => edge.node).filter(x => x.localFile.childImageSharp)
  } catch (error) {
  }
  return (
    <Layout>
      <SEO 
        title={`${capitalizeFirst(categoryName)}`}
      />
      { html && <div className="html" dangerouslySetInnerHTML={{__html: html}} /> }
      <div className="images">
        {!!images.length && <Gallery images={images} />}
      </div>
    </Layout>
  )
}
export const query = graphql`
query getDropBoxNodeByCategory($categoryNameRegex: String!) {
    allDropboxNode(filter: {path: {regex: $categoryNameRegex}}) {
      edges {
        node {
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
              fixed(width: 1500, quality: 80) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    dropboxNode(path: {regex: $categoryNameRegex}) {
      name
      path
      localFile {
        childMarkdownRemark {
          html
        }
      }
    }
  }
  
`