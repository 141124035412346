import React from "react";
import { Link, graphql, useStaticQuery } from 'gatsby';


export default (items) => {
    const data = useStaticQuery(graphql`
{
    allDropboxNode {
        edges {
          node {
            path
          }
        }
      }
}
`)
const regex = /(\/[0-9]{2}[a-zA-Z]*\/)/
const categories =
Array.from(new Set(data.allDropboxNode.edges.map(e => e.node.path)
    .map(e => e.match(regex))
    .filter(x => x)
    .flatMap(x => x[0])
    .map(x => x.toLowerCase())
    .sort((x, y) => parseInt(x.substring(1, 3)) - parseInt(y.substring(1, 3)))
    .map(x => x.substring(3, x.length - 1))))
  return (
    <ul className="menu">
        {categories.map(category => (
            <li key={category}>
                <Link activeClassName="active" to={`/${category}`}>
                    {category}
                </Link>
            </li>
        ))}
    </ul>
  );
};