import React, { Component } from 'react'
import Image from 'gatsby-image'
import Carousel, { ModalGateway, Modal} from 'react-images'
import styled from 'styled-components'

const GalleryLink = styled.a`
  .gatsby-image-wrapper {
    
  }
  .gatsby-image-wrapper {
  transition: opacity 100ms ease-in-out, -webkit-filter 300ms ease-in-out, transform 300ms ease-in-out;
  }
  &:hover {
    .gatsby-image-wrapper {
        opacity: .8
    }
  }
`

class Gallery extends Component {
  constructor() {
    super()
    this.showImage = this.showImage.bind(this)
    this.state = {
      lightboxIsOpen: false,
    }
  }

  showImage = (index, e) => {
    e.preventDefault()
    this.setState({
      lightboxIsOpen: true,
      currentImage: index,
    })
  }

  closeLightbox = () => {
    this.setState({
      lightboxIsOpen: false,
      currentImage: null,
    })
  }

  gotoPrevLightboxImage = () => {
    this.setState(state => ({
      currentImage: state.currentImage - 1,
    }))
  }

  gotoNextLightboxImage = () => {
    this.setState(state => ({
      currentImage: state.currentImage + 1,
    }))
  }

  render() {
    const { images } = this.props
    return (
      <>
        {images.map((image, index) => (
          <GalleryLink
            key={image.id}
            href="#"
            onClick={e => this.showImage(index, e)}
            style={{ display: 'inline-block' }}
          >
            <div><Image fluid={image.localFile.childImageSharp.fluid} /></div>
          </GalleryLink>
        ))}
        <ModalGateway>
            {this.state.lightboxIsOpen && <Modal onClose={this.closeLightbox}>
                
        <Carousel
          views={images.map(image => ({
              key: image.id,
              src: image.localFile.childImageSharp.fixed.src,
              thumbnail: image.localFile.childImageSharp.fluid.src,
              caption: 'Foto: Jonne Bentlöv',
            }))}
            backdropClosesModal
            enableKeyboardInput
            onClickPrev={this.gotoPrevLightboxImage}
            onClickNext={this.gotoNextLightboxImage}
            
            currentIndex={this.state.currentImage}
            />
            </Modal>}
        </ModalGateway>
      </>
    )
  }
}

export default Gallery