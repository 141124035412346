import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./logo";
import Menu from './menu'
const Header = ({ siteTitle }) => (
  <header className="header">
      <Link
          to="/me"
          title="me"
        >
        <Logo />
        </Link>
      <Menu />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
