import React from "react";

const Aperture = props => (
  <svg width="100%" height="100%" viewBox="0 0 371 372">
    <g>
      <path d="M316.156,54.842c-34.98,-34.976 -81.488,-54.242 -130.957,-54.242c-49.469,0 -95.976,19.266 -130.957,54.242c-34.976,34.981 -54.242,81.488 -54.242,130.957c0,49.469 19.266,95.977 54.242,130.957c34.981,34.981 81.488,54.242 130.957,54.242c49.469,0 95.977,-19.261 130.957,-54.242c34.981,-34.98 54.242,-81.488 54.242,-130.957c0,-49.469 -19.261,-95.976 -54.242,-130.957Zm38.559,95.199c-0.258,0.188 -0.508,0.387 -0.738,0.618l-63.907,63.91l0,-166.633c6.149,4.695 12.035,9.828 17.602,15.394c24.113,24.114 40.23,54.09 47.043,86.711Zm-204.106,128.629l-58.277,-58.281l0,-75.359l52.098,-52.098l75.359,0l58.281,58.277l0,75.36l-52.101,52.101l-75.36,0Zm127.461,-239.132l0,94.703l-119.593,-119.594c8.761,-1.352 17.687,-2.047 26.722,-2.047c33.406,0 65.367,9.398 92.871,26.938Zm-215.34,23.792c22.84,-22.843 50.942,-38.507 81.571,-45.89l63.488,63.492l-160.453,0c4.699,-6.152 9.828,-12.035 15.394,-17.602Zm-23.792,29.602l88.523,0l-114.172,114.168c-0.855,-7.012 -1.289,-14.125 -1.289,-21.301c0,-33.406 9.398,-65.367 26.938,-92.867Zm-23.25,128.625c0.253,-0.187 0.503,-0.387 0.734,-0.617l63.91,-63.91l0,166.636c-6.152,-4.699 -12.039,-9.828 -17.602,-15.394c-24.117,-24.117 -40.23,-54.094 -47.042,-86.715Zm76.644,110.508l0,-94.703l119.594,119.593c-8.766,1.348 -17.692,2.043 -26.727,2.043c-33.406,0 -65.367,-9.398 -92.867,-26.933Zm215.34,-23.793c-22.844,22.84 -50.946,38.504 -81.574,45.887l-63.489,-63.489l160.453,0c-4.695,6.149 -9.824,12.035 -15.39,17.602Zm23.793,-29.602l-88.524,0l114.168,-114.172c0.856,7.016 1.289,14.125 1.289,21.301c0,33.406 -9.398,65.367 -26.933,92.871Z" />
    </g>
  </svg>
);

export default props => {
  return (
    <div className="logo">
      <h1>photo&nbsp;by&nbsp;<span>Jonne</span></h1>
      <span
        style={{
          borderRight: "1px solid black",
          height: "25px",
          display: "block"
        }}
      />
      <div className="header--logo">
        <Aperture />
      </div>
    </div>
  );
};
